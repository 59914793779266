import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function ContactPage() {
  return (
    <Layout>
      <SEO
        keywords={[`contact`]}
        title="Contact"
      />
      <section className="mt-32">
        <div className="container mx-auto px-4">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-4/12 ml-auto mr-auto px-4 mb-20 sm:mb-0">
              <img
                alt="..."
                className="max-w-full rounded-lg shadow-lg"
                src="https://images.unsplash.com/photo-1528260249572-a56fc30084ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1575&q=80"
              />
            </div>
            <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
              <div className="md:pr-12">
                <h3 className="text-3xl font-semibold">
                  Contact
                </h3>
                <p className="mt-4 text-lg leading-relaxed text-gray-600">
                  We take our commitment to our users seriously. If you need our help with your user account, have questions about how to use the Forest, any feedback or are experiencing technical difficulties, please do not hesitate to contact us.
                </p>
                <p className="mt-4 text-lg leading-relaxed text-gray-600">
                  Plain email is the best way to contact us:
                  <br/>
                  <span className="font-black">info@getforest.io</span>

                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default ContactPage;
